import React from 'react';

const Temp = () => {
  return (
    <div>
      <p>Under Construction</p>
    </div>
  );
};

export default Temp;
